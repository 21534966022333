<template>
  <div>
    <v-card flat>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title
        v-if="isTranslating"
      >
        Application Configuration Translate
        {{ selectedLanguage }}
      </v-card-title>
      <v-card-title
        v-else-if="ticketing.isProfiling"
      >
        Profiling Hierarchy
      </v-card-title>
      <v-card-title v-else-if="routeType == 'URL'">
        Url Hierarchy
      </v-card-title>
      <v-card-title
        v-else-if="routeType == 'REPORT-HUB'"
        class="d-flex justify-space-between"
      >
        Report Hub Hierarchy
        <v-btn
          v-if="services.existingServicesConfiguration.length > 0 || services.hierarchyItems.length > 0"
          class="disable-events"
          color="color_green"
          outlined
          small
        >
          {{ viewMode === 0 ? 'Table view' : 'Tree view' }}
        </v-btn>
        <v-btn-toggle
          v-else
          v-model="viewMode"
          color="#0da344"
          mandatory
        >
          <v-btn
            small
          >
            Table view
          </v-btn>
          <v-btn
            small
          >
            Tree view
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-title v-else>
        Documental Hierarchy
      </v-card-title>
      <v-card-text>
        <p v-if="routeType === 'REPORT-HUB' && viewMode === 0 && !isPolicing">
          File Name should be like <b>Name_Date_Hour.pdf</b> - Exaple: <b>Nome_Bollettino_2024-01-18_0800.pdf</b>
        </p>
        <v-icon
          v-if="!isTranslating && !isPolicing && !ticketing.isProfiling"
          size="30"
          @click="createFather()"
        >
          mdi-plus-circle-outline
        </v-icon>
        <v-icon
          v-if="!isTranslating && !isPolicing && !ticketing.isProfiling && routeType === 'REPORT-HUB' && viewMode === 1"
          size="30"
          class="ml-3"
          @click="fillWith()"
        >
          mdi-list-box-outline
        </v-icon>
        <v-icon
          v-if="ticketing.isProfiling && services.hierarchyItems.length <= 0"
          size="30"
          @click="createFather()"
        >
          mdi-plus-circle-outline
        </v-icon>
        <div v-if="services.showCreateFather">
          <configuration-service-form-father
            :father-form="service_father_form"
            :is-editing="showEditFather"
            :is-translating="isTranslating"
            :selected-language="selectedLanguage"
            :route-type="routeType"
            :view-mode="!viewMode"
            :is-auto-fill="autoFill"
            @set-auto-fill="autoFill = false"
          />
        </div>
        <div v-if="services.showCreateChildren">
          <configuration-service-form-children
            :father="selectedFather"
            :children-form="service_children_form"
            :is-editing="showEditChildren"
            :is-translating="isTranslating"
            :route-type="routeType"
            :is-profiling="true"
            :is-auto-fill="autoFill"
            @set-auto-fill="autoFill = false"
          />
        </div>
        <div v-if="ticketing.showProfilingHierarchy">
          <profiling-hierarchy-checkbox
            :profiling-item="profilingItem"
            :selected-ticketing-service="selectedTicketingService"
          />
        </div>
        <div v-if="ticketing.showProfilingHierarchyAppUser">
          <profiling-hierarchy-app-user-table :profiling-item="profilingItem" />
        </div>
        <div
          v-if="services.hierarchyItems.length > 0 && isPolicing && (routeType === 'REPORT-HUB' && viewMode === 1)"
          class="text-right"
        >
          Add all nodes
          <v-icon
            size="25"
            class="ma-3"
            @click="addAllFoldersToPolicy()"
          >
            mdi-folder-plus
          </v-icon>
        </div>
        <v-treeview :items="services.hierarchyItems">
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item.father_id == ''">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon v-else-if="item.children.length > 0">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon
              v-else-if="
                item.children.length == 0 &&
                  item.external_link != undefined &&
                  item.external_link != '' &&
                  item.father_id != ''
              "
              :title="item.external_link"
            >
              mdi-file-link
            </v-icon>
            <v-icon
              v-else-if="
                item.children.length == 0 &&
                  item.path_s3_bucket != undefined &&
                  item.path_s3_bucket != '' &&
                  item.father_id != ''
              "
            >
              mdi-file-pdf-box
            </v-icon>
            <v-icon v-else>
              {{ routeType === 'REPORT-HUB' ? 'mdi-folder' : 'mdi-file' }}
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <v-row>
              <v-col
                v-if="!isTranslating && !isPolicing && !ticketing.isProfiling"
                cols="2"
              >
                {{ item.sort_id }}
                <span>
                  <v-icon @click="sortUp(item)">
                    mdi-arrow-up-drop-circle-outline
                  </v-icon>
                </span>
                <span v-if="item.sort_id > 1">
                  <v-icon @click="sortDown(item)">
                    mdi-arrow-down-drop-circle-outline
                  </v-icon>
                </span>
              </v-col>
              <v-col cols="4">
                <span>
                  {{ item.node_name }}
                </span>
              </v-col>
              <v-col cols="6">
                <span>
                  {{ item.node_description }}
                </span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:append="{ item }">
            <v-icon
              v-if="(routeType !== 'REPORT-HUB' || (routeType === 'REPORT-HUB' && viewMode === 1)) && (!isTranslating && !isPolicing && isCreatingProfiling)"
              size="18"
              class="mr-3"
              :disabled="
                (item.external_link != '' && item.external_link != undefined) ||
                  (item.path_s3_bucket != '' &&
                    item.path_s3_bucket != undefined)
              "
              @click="createChildren(item)"
            >
              mdi-plus-circle
            </v-icon>
            <v-icon
              v-if="!isPolicing && isCreatingProfiling"
              size="18"
              class="mr-1"
              @click="edit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="(routeType === 'REPORT-HUB' && viewMode === 1) && (!isTranslating && !isPolicing && isCreatingProfiling)"
              size="18"
              class="ma-1"
              @click="fillWith(item)"
            >
              mdi-list-box-outline
            </v-icon>
            <v-icon
              v-if="ticketing.isProfiling && !isCreatingProfiling"
              size="18"
              class="ml-1"
              @click="changeProfiling(item)"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-if="ticketing.isProfiling && !isCreatingProfiling"
              size="21"
              class="ml-2"
              @click="changeProfilingUser(item)"
            >
              mdi-account-plus
            </v-icon>
            <span v-if="!isTranslating && !isPolicing && isCreatingProfiling">
              <delete
                :size="18"
                @deleted="deleteItem(item)"
              />
            </span>
            <v-icon
              v-if="
                ((routeType === 'DOC' && item.path_s3_bucket !== '') ||
                  (routeType === 'URL' && item.external_link !== '') ||
                  (routeType === 'REPORT-HUB')) &&
                  item.father_id !== '' &&
                  item.children.length == 0 &&
                  isPolicing
              "
              size="18"
              class="mr-1"
              @click="addNodeToPolicy(item)"
            >
              mdi-plus-circle
            </v-icon>
            <v-icon
              v-if="item.children.length > 0 && isPolicing"
              size="18"
              class="mr-1"
              @click="addFolderToPolicy(item)"
            >
              mdi-folder-plus
            </v-icon>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
    <v-btn
      v-if="!isPolicing && !isTranslating && !ticketing.isProfiling"
      light
      color="color_green"
      style="color:white"
      class="mt-2"
      rounded
      small
      @click="reorder"
    >
      reorder hierarchy
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  deleteItemNested,
  nestHierarchyItems,
  findItemNested
} from '@/utils.js';

export default {
  components: {
    ConfigurationServiceFormFather: () =>
      import('@/components/services/ConfigurationServiceFormFather'),
    ConfigurationServiceFormChildren: () =>
      import('@/components/services/ConfigurationServiceFormChildren'),
    delete: () => import('@/components/utils/Delete'),
    ProfilingHierarchyCheckbox: () =>
      import('@/components/customer/ProfilingHierarchyCheckbox'),
    ProfilingHierarchyAppUserTable: () =>
      import('@/components/customer/ProfilingHierarchyAppUserTable')
  },
  props: {
    routeType: {
      type: String,
      default: null
    },
    isTranslating: {
      type: Boolean,
      default: false
    },
    isPolicing: {
      type: Boolean,
      default: false
    },
    selectedLanguage: {
      type: String,
      default: null
    },
    selectedTicketingService: {
      type: Object,
      default: null
    },
    isCreatingProfiling: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      loading: false,
      overlay: false,
      autoFill: false,
      e6: 1,
      viewMode: 1,
      selectedFather: {},
      service_father_form: {},
      service_children_form: {},
      profilingItem: {},
      showDeleteDialog: false,
      showEditFather: false,
      showEditChildren: false,
      nodeToAdd: []
    };
  },
  computed: {
    ...mapState(['services', 'policy', 'ticketing'])
  },
  watch: {
    viewMode(newValue) {
      if (this.routeType === 'REPORT-HUB') {
        this.$emit('update-crm-folder', Boolean(newValue));
      }
    }
  },
  async mounted() {
    this.services.storeFatherSort_id = 0;
    this.services.storeChildrenSort_id = 0;

    if (!this.ticketing.isProfiling) {
      this.services.hierarchyItems = [];
      this.services.hierarchyItemsLinear = [];
      this.services.existingServicesConfiguration = [];

      if (
        this.services.service_id === '' ||
        this.services.storeServiceForm == {}
      ) {
        this.$router.go(-1);
      } else {
        this.getServicesConfiguration();
      }
    } else {
      this.services.hierarchyItems = [];
      this.services.hierarchyItemsLinear = [];

      this.overlay = true;
      await this.storeGetProfilingTicketingService(this.selectedTicketingService.service_id);
      this.overlay = false;

      if (this.ticketing.storeProfilingTicketing.hierarchy.length > 0) {
        this.services.hierarchyItems = nestHierarchyItems(this.ticketing.storeProfilingTicketing.hierarchy);
      } else {
        this.services.hierarchyItems = [];
      }
    }
  },
  methods: {
    ...mapActions([
      'storeGetUrlServiceConfiguration',
      'storeGetDocumentalServiceConfiguration',
      'storeGetReportHubServiceConfiguration',
      'storeToastMessage',
      'storeGetLinkedAppUserToProfilingNode',
      'storeGetProfilingTicketingService',
      'storeGetProfilingTicketingService',
      'storeGetTicketingServiceConfiguration'
    ]),
    async getServicesConfiguration() {
      if (this.routeType === 'URL') {
        this.overlay = true;
        await this.storeGetUrlServiceConfiguration(this.services.service_id);
        this.overlay = false;
        this.services.existingServicesConfiguration = this.services.hierarchyItemsLinear;
        this.services.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
      }

      if (this.routeType === 'DOC') {
        this.overlay = true;
        await this.storeGetDocumentalServiceConfiguration(
          this.services.service_id
        );
        this.overlay = false;
        this.services.existingServicesConfiguration = this.services.hierarchyItemsLinear;
        this.services.hierarchyItemsLinear.sort(
          (a, b) => a.sort_id - b.sort_id
        );
        this.services.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
      }

      if (this.routeType === 'REPORT-HUB') {
        this.overlay = true;
        await this.storeGetReportHubServiceConfiguration(
          this.services.service_id
        );
        this.overlay = false;
        this.viewMode = this.services.hierarchyItemsLinear[0]?.crm_folder ? 0 : 1;
        // eslint-disable-next-line no-unused-vars
        this.services.existingServicesConfiguration = this.services.hierarchyItemsLinear.map(({ path_s3_bucket, ...rest }) => rest);
        this.services.hierarchyItemsLinear.sort(
          (a, b) => a.sort_id - b.sort_id
        );
        this.services.hierarchyItems = nestHierarchyItems(
          this.services.hierarchyItemsLinear
        );
      }
    },
    deleteItem(item) {
      deleteItemNested(
        this.services.hierarchyItems,
        item.node_id,
        'children',
        this.services.deletedItemsServicesConfiguration
      );
    },
    async getUrlServicesConfiguration() {
      await this.storeGetUrlServiceConfiguration(this.services.service_id);
      this.services.urlServicesConfigurationNotEdited = this.services.hierarchyItemsLinear;
      this.services.hierarchyItems = nestHierarchyItems(
        this.services.hierarchyItemsLinear
      );
    },
    createChildren(item) {
      if (item.children.length == 0 && !this.ticketing.isProfiling) {
        this.services.storeChildrenSort_id = 0;
      }

      this.services.showCreateChildren = true;
      this.selectedFather = item;
      this.service_children_form = {
        node_name: '',
        node_description: '',
        father_id: '',
        external_link: '',
        children: []
      };
      this.showEditChildren = false;
    },
    createFather() {
      this.services.showCreateFather = true;
      this.service_father_form = {
        node_name: '',
        node_description: ''
      };
      this.showEditFather = false;
    },
    edit(item) {
      if (item.father_id === '') {
        this.services.showCreateFather = true;
        this.service_father_form = JSON.parse(JSON.stringify(item));
        this.showEditFather = true;
      } else {
        this.services.showCreateChildren = true;
        this.service_children_form = JSON.parse(JSON.stringify(item));
        this.showEditChildren = true;
      }
    },
    addNodeToPolicy(item) {
      this.policy.nodeToDelete = this.policy.nodeToDelete.filter(e => item.node_id !== e.node_id);
      const alredyExist = this.policy.nodeToAdd.some(e => item.node_id == e.node_id);

      if (alredyExist) {
        this.storeToastMessage({
          text: `Node ${item.node_name} alredy added`,
          type: 'warning'
        });
      } else {
        this.policy.nodeToAdd.push(item);
      }
    },
    addAllFoldersToPolicy() {
      this.services.hierarchyItems.forEach(item => this.addFolderToPolicy(item));
    },
    addFolderToPolicy(item) {
      if (item.children.length > 0) {
        const linearArray = [];

        findItemNested(
          item.children,
          'children',
          linearArray,
          this.routeType
        );

        linearArray.forEach(child => {
          if ((child.external_link !== '' && this.routeType === 'URL') || (child.path_s3_bucket !== '' && this.routeType === 'DOC') || this.routeType === 'REPORT-HUB') {
            const alredyExist = this.policy.nodeToAdd.some(e => child.node_id == e.node_id);

            if (alredyExist) {
              this.storeToastMessage({
                text: `Node ${child.node_name} alredy added`,
                type: 'warning'
              });
            } else {
              this.policy.nodeToAdd.push(child);
            }
          }
        });
      }
    },
    sortUp(item) {
      if (item.sort_id >= 1) {
        ++item.sort_id;
      }
    },
    sortDown(item) {
      if (item.sort_id > 1) {
        --item.sort_id;
      }
    },
    reorder() {
      this.services.hierarchyItemsLinear = [];
      findItemNested(
        this.services.hierarchyItems,
        'children',
        this.services.hierarchyItemsLinear,
        this.routeType
      );
      this.services.hierarchyItems = nestHierarchyItems(
        this.services.hierarchyItemsLinear
      );
    },
    async changeProfiling(item) {
      this.ticketing.selectedRequestTypes = { permission_name: [], permission_id: [] };
      this.ticketing.selectedPermissions = { permission_name: [], permission_id: [] };
      this.ticketing.selectedNotifications = { permission_name: [], permission_id: [] };

      this.ticketing.oldSelectedRequestTypes = { permission_name: [], permission_id: [] };
      this.ticketing.oldSelectedPermissions = { permission_name: [], permission_id: [] };
      this.ticketing.oldSelectedNotifications = { permission_name: [], permission_id: [] };

      this.ticketing.ticketingServiceConfigurationDocumentType = { document_type: [], ticket_id: [] };

      this.ticketing.profilingArray = [];
      this.ticketing.deletedProfilingArray = [];
      this.profilingItem = item;
      this.getTicketingProfiling(item);
    },
    async getTicketingProfiling(item) {
      this.overlay = true;
      await this.storeGetProfilingTicketingService(this.selectedTicketingService.service_id);
      this.overlay = false;

      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(this.selectedTicketingService.service_id);
      this.overlay = false;

      this.ticketing.ticketingServiceConfiguration.config?.forEach(item => {
        if (item.document_type != '') {
          this.ticketing.ticketingServiceConfigurationDocumentType.document_type.push(item.document_type);
          this.ticketing.ticketingServiceConfigurationDocumentType.ticket_id.push(item.ticket_id);
        }
      });

      this.ticketing.showProfilingHierarchy = true;

      if (this.ticketing.storeProfilingTicketing.profiling.length > 0) {

        this.ticketing.storeProfilingTicketing.profiling.forEach(profiling => {
          if (profiling.permission_type == 'requests' && item.node_id == profiling.node_id) {
            const [permission_name] = profiling.permission_name.split(', ');

            this.ticketing.selectedRequestTypes.permission_name.push(permission_name);
            this.ticketing.selectedRequestTypes.permission_id.push(profiling.permission_id);

            this.ticketing.oldSelectedRequestTypes = JSON.parse(JSON.stringify(this.ticketing.selectedRequestTypes));
          }

          if (profiling.permission_type == 'permissions' && item.node_id == profiling.node_id) {
            this.ticketing.selectedPermissions.permission_name.push(profiling.permission_name);
            this.ticketing.selectedPermissions.permission_id.push(profiling.permission_id);

            this.ticketing.oldSelectedPermissions = JSON.parse(JSON.stringify(this.ticketing.selectedPermissions));
          }

          if (profiling.permission_type == 'notifications' && item.node_id == profiling.node_id) {
            this.ticketing.selectedNotifications.permission_name.push(profiling.permission_name);
            this.ticketing.selectedNotifications.permission_id.push(profiling.permission_id);

            this.ticketing.oldSelectedNotifications = JSON.parse(JSON.stringify(this.ticketing.selectedNotifications));
          }
        });
      }
    },
    async changeProfilingUser(item) {
      this.profilingItem = item;
      this.overlay = true;
      await this.storeGetLinkedAppUserToProfilingNode(item.node_id);
      this.overlay = false;
      this.ticketing.showProfilingHierarchyAppUser = true;
    },
    fillWith(item) {
      this.autoFill = true;

      if (item) {
        this.createChildren(item);
      } else {
        this.createFather();
      }
    }
  }
};
</script>

<style scoped>
.disable-events {
  pointer-events: none
}
</style>
